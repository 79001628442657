import React from 'react';
import profileImg from '../images/profile-img.jpg';

function AboutMobile() {
  return (
    <section id="about" className="about py-16">
      <div className="container mx-auto px-4" data-aos="fade-up">
        <div className="section-title text-center mb-8">
          <h2 className="text-3xl font-bold">About me</h2>
          <div className="max-w-md mx-auto">
          <p className="text-lg">
            I'm a Professional problem solver, in love with DevOps culture. 
            I can help you bring security and scalability to your solution, decreasing your stress and increasing your profit.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-full mb-8">
            <img src={profileImg} className="img-fluid rounded shadow-lg mx-auto" alt="Profile" />
          </div>
          <div className="w-full text-left">
            <h3 className="text-2xl font-semibold mb-4">Site Reliability Engineer & Cloud Specialist</h3>
            <h4 className="text-xl font-semibold mb-4">Curiosities about me:</h4>
            <div className="flex flex-col items-start">
              <div className="w-full">
                <ul className="space-y-6 text-base mt-6">
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Circadian Cycle:</strong> <span className="ml-2 text-white">I sleep before 22pm</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Color:</strong> <span className="ml-2 text-white">I might like blue</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Kids:</strong> <span className="ml-2 text-white">Still don't, but I will :)</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Married:</strong> <span className="ml-2 text-white">Yesss :D</span></li>
                </ul>
              </div>
              <div className="w-full mt-4">
                <ul className="space-y-6 text-base mt-6">
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Where do I live?</strong> <span className="ml-2 text-white">Sharks City, Brazil (Recife)</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Degree:</strong> <span className="ml-2 text-white">Computer Engineering</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">I played:</strong> <span className="ml-2 text-white">League of Legends and Tibia ¯\_(ツ)_/¯</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Shy:</strong> <span className="ml-2 text-white">I was afraid to talk even with the waiter</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMobile;
